import React, { useEffect, useState } from "react";
import Loader from "react-loaders";
import AnimatedLetters from "../AnimatedLetters";
import "./index.scss";
import Maine from './../../assets/images/projects/maine.png';
import Blessed from './../../assets/images/projects/blessed.jpeg';
import Taz from './../../assets/images/projects/djTaz.png';
import Web3 from './../../assets/images/projects/web3.png';

const projects = [
  // {
  //     image: Maine,
  //     name: 'Maine',
  //     link: 'https://maine-recruitment.com/'
  // },
  {
    image: Blessed,
    name: 'Blessed To Be',
    link: 'http://blessedtobe.co.uk/',
  },
  {
    image: Taz,
    name: 'DJ Taz',
    link: 'https://djtaz.co.uk/',
  },
  {
    image: Web3,
    name: 'Web 3',
    link: 'https://ajay-web3.io',
  },
]


const Portfolio = () => {
    const [letterClass, setLetterClass] = useState('text-animate');

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => {
            clearTimeout(timer);
        }
    });
    return (
        <>
            <div className="container portfolio-page">
                <h1 className="page-title">
                    <AnimatedLetters
                        letterClass={letterClass}
                        strArray={"Portfolio".split("")}
                        idx={15}
                    />
                </h1>
                <div>
                    <div className="images-container">
                        {
                            projects.map((item, index) => (
                                <div className="image-box">
                                    <div className="posters">
                                        <div className="card">
                                            <img className="poster" src={item.image} alt=""/>
                                            <div className="studio-button">
                                                <div className="studio-button-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>
                                                </div>
                                                <div className="studio-button-label">
                                                    <a href={item.link} >{item.name}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <Loader type="ball-scale-ripple-multiple"  active/>
        </>
    );
}

export default Portfolio;
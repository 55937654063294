export const skills = [
    'Jquery',
    'JSON',
    'HTML',
    'CSS',
    'SASS',
    'JavaScript',
    'React',
    'SQL',
    'Next',
    'AWS',
    'Shopify',
    'Flutter',
    'Typescript',
    'APIs',
    'Python',
    'NodeJS',
    'Bootstrap',
    'Three.js',
    'Figma',
    'Adobe XD',
    'Spline',
    'Git',
    'Webflow',
];